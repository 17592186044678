body.login {
    height: 100%;
    width: 100%;
    background: #eee;

    &:before {
        display: none;
    }

    .login-modal {
        @width: 440px;
        @height: 250px;
        width: @width;
        min-height: @height;
        margin-top: -(@height/2) - 120px;
        margin-left: -(@width/2);
        position: fixed;
        top: 50%;
        left: 50%;

        .login-box {
            padding: 0px 50px 30px;
            background: #fff;
            border: 1px solid rgba(0,0,0,0.1);
            position: relative;
            .box-shadow( 0 0 10px rgba(0,0,0,0.1));
            border-radius: 10px;

            #authorization {
                color: #aaa;
            }

            .pageTitle {
                @height: 140px;
                line-height: @height;
                border-bottom: 1px solid rgba(0,0,0,0.08);
                overflow: hidden;
                padding: 0 20px;
                margin: 0 -20px 30px;
                position: relative;
                vertical-align: middle;

                .login-logo {
                    margin-top: 10px;
                    width: 100%;
                    height: @height;
                    line-height: @height;
                    background: url('@{variable-img-url}/logologin.png') no-repeat center;
                    background-size: auto 60%;
                    float: left;
                }

                .header {
                    display: none;
                    height: @height;
                    line-height: @height;
                    padding: 0 30px;
                    background: @base-color;
                    color: #fff;
                    position: absolute;
                    right: 0;
                    top: -20px;
                    font-size: 14px;
                    text-transform: uppercase;
                }
            }
        }

        .login-form {
            margin-top: 10px;

            .form-group {
                padding-right: 0px;

                input.form-control {
                    @height: 36px;
                    height: @height;
                    line-height: @height;
                    padding: 0px;
                    padding-left: @height - 5px;
                    -webkit-appearance: none;
                    outline: none;
                    border-radius: 0;

                    &:focus {
                        box-shadow: none;
                        border: 1px solid lighten(@base-color, 20%);
                    }

                    @media screen and (-webkit-min-device-pixel-ratio:0) {
                        //padding-left: 4px;
                    }

                    &.user {
                        background: url('@{variable-img-url}/login_user.png') no-repeat -2px center;
                    }

                    &.password {
                        background: url('@{variable-img-url}/login_password.png') no-repeat -2px center;
                    }
                }
            }

            .btn {
                width: 100%;
                @height: 40px;
                height: @height;
                line-height: @height;
                padding: 0 25px;

                [class^="fa"] {
                    margin: 0 0 0 5px;
                    height: @height;
                    line-height: @height;
                }
            }
        }

        .language-row {
            margin-bottom: 15px;
            height: 30px;

            .form-control {
                height: auto;
            }
        }

        .version {
            margin-top: 5px;
            text-align: right;
            color: #999;
            text-transform: capitalize;
        }
    }

    .validation-summary-errors {
        padding: 20px;
        background: fade(@danger-color, 20%);
        border: 1px solid rgba(0, 0, 0, 0.1);
        color: darken(@danger-color, 30%);
        margin-bottom: 10px;

        ul {
            margin: 0px;
        }
    }

    .field-validation-error {
        color: fade(@danger-color, 60%);
    }
}
